import { operate } from '../util/lift';
import { createOperatorSubscriber } from './OperatorSubscriber';
import { innerFrom } from '../observable/innerFrom';
import { noop } from '../util/noop';
export function skipUntil(notifier) {
  return operate((source, subscriber) => {
    let taking = false;
    const skipSubscriber = createOperatorSubscriber(subscriber, () => {
      skipSubscriber === null || skipSubscriber === void 0 ? void 0 : skipSubscriber.unsubscribe();
      taking = true;
    }, noop);
    innerFrom(notifier).subscribe(skipSubscriber);
    source.subscribe(createOperatorSubscriber(subscriber, value => taking && subscriber.next(value)));
  });
}
