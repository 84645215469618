import { async } from '../scheduler/async';
import { isValidDate } from '../util/isDate';
import { timeout } from './timeout';
export function timeoutWith(due, withObservable, scheduler) {
  let first;
  let each;
  let _with;
  scheduler = scheduler !== null && scheduler !== void 0 ? scheduler : async;
  if (isValidDate(due)) {
    first = due;
  } else if (typeof due === 'number') {
    each = due;
  }
  if (withObservable) {
    _with = () => withObservable;
  } else {
    throw new TypeError('No observable provided to switch to');
  }
  if (first == null && each == null) {
    throw new TypeError('No timeout provided.');
  }
  return timeout({
    first,
    each,
    scheduler,
    with: _with
  });
}
